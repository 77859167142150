import React, { useState } from 'react';
import Menu from './Menu';
import '../../assets/scss/components/_navbar.scss';
import MenuIcon from '../../assets/icons/menu.svg';
import LogoutIcon from '../../assets/icons/logout.svg';
import AvatarIcon from '../../assets/icons/avatar.png';
import logo from '../../assets/logos/logo-menu.svg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { appConfig } from '../../config/app';

const Navbar = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { user, logout } = useAuth();

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleLogout = () => {
    logout(navigate);
  };

  const handleEditUser = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/gestion-usuarios/usuarios/editar/${user.id}`, { state: { from: 'Ajustes' } });
  };

  const baseURL = appConfig.apiConfig.baseURL;
  const userName = user ? user.name : '';
  const userRol = user ? user.rol?.label : '';
  const userImg = user ? baseURL + user.foto : AvatarIcon;

  // TODO: cambiar el avatar por la imagen del usuario recibida al hacer login
  return (
    <div className="navigation-bar">
        <div className="flex">
          <div className="left-icon" onClick={toggleMenu}>
            <img src={MenuIcon} alt="Menu Icon" />
          </div>

          <img className="logo-img"
               src={logo} alt="Home"
               onClick={() => navigate(`/home`)} />
        </div>
        <div className="right-icon"
             onMouseEnter={openModal}>
          <img src={userImg} alt="Avatar Icon" />
        </div>
      {isModalOpen && (
        <div className="logout-modal"
             onMouseLeave={closeModal}>
          <div>
            <img src={userImg} alt="Avatar Icon" />
            <div>
              <p>{userName}</p>
              <p>{userRol}</p>
            </div>
          </div>
          <div>
            <img src={LogoutIcon} alt="Logout" />
            <a href="#" onClick={handleEditUser}>Editar perfil</a>
          </div>
          <div className="logout-div">
            <img src={LogoutIcon} alt="Logout" />
            <a href="#" onClick={handleLogout}>Cerrar sesión</a>
          </div>
        </div>
      )}
      <Menu isOpen={isMenuOpen} onClose={closeMenu} />
    </div>
  );
};

export default Navbar;