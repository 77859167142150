import { FC, ReactNode } from 'react';
import React, { useContext } from 'react';
import {AlertModalType, Color} from '../../shared/types';
import SuccessIcon from '../../assets/icons/circle-check-2-green.svg';
import AlertIcon from '../../assets/icons/alert.svg';
import ErrorIcon from '../../assets/icons/alert-octagon.svg';
import CloseIcon from '../../assets/icons/close.svg';
import Button from '../Button';
import '../../assets/scss/components/_alert-modal.scss';
import { ErrorContext, ErrorContextType } from '../../context/ErrorContext';

interface AlertModalProps {
  /**
   * Texto principal
   */
  title?: string;

  /**
   * Texto descriptivo
   */
  description?: string;

  /**
   * Tipo
   */
  type?: AlertModalType;

  /**
   * Agregar clases de CSS propias
   */
  className?: string;

  /**
   * Función onSubmit
   */
  onSubmit?: () => void;

  /**
   * Función onClose
   */
  onClose?: () => void;
}

const AlertModal: React.FC<AlertModalProps> = ({
  title,
  description,
  type,
  className,
  onSubmit,
  onClose,
  ...AlertModalProps }) => {

  const { clearError } = useContext<ErrorContextType>(ErrorContext);

  let icon;

  switch (type) {
    case 'ok':
      icon = <img src={SuccessIcon} alt="OK" />;
      break;
    case 'warning':
      icon = <img src={AlertIcon} alt="Advertencia" />;
      break;
    case 'error':
      icon = <img src={ErrorIcon} alt="Error" />;
      break;
    default:
      icon = null;
      break;
  }

  const combinedClassName = className ? `modal-alert-container ${className} ${type}` : `modal-alert-container ${type}`;

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function getColor(type: AlertModalType): Color {
    switch (type) {
      case 'ok':
        return 'primary-ok';
      case 'error':
        return 'primary-error';
      default:
        return 'primary-ok';
    }
  }

  const handleOnClose = () => {
    clearError();
    onClose?.();
  };

  const handleOnSubmit = () => {
    clearError();
    onSubmit?.();
  };

  return (
    <>
      <div className={combinedClassName}>
        <div className="modal-header">
          <img src={CloseIcon} onClick={onClose} alt="Close Icon" />
        </div>

        <div className="modal-body">
          {icon}

          {title && (
            <p>{capitalizeFirstLetter(title)}</p>
          )}

          {description && (
            <p>{description}</p>
          )}

            <div className="modal-footer">
              { type == 'warning' && (
                <>
                  <Button color="outline-warning" onClick={handleOnClose}>Cancelar</Button>
                  <Button color="primary-warning" onClick={handleOnSubmit}>Confirmar</Button>
                </>
              )}

              { (type == 'error' || type == 'ok') && (
                <Button color={getColor(type)} onClick={handleOnSubmit}>Entendido</Button>
              )}
            </div>

        </div>
      </div>
      <div className="modal-backdrop"></div>
    </>
  );
};

export default AlertModal;