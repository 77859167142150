import React, { FC } from 'react';
import cn from 'classnames';
import '../../assets/scss/components/_select.scss';

export interface Option {
  text: string;
  value: string | number;
}

export interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: Option[];
  label?: string;
  id?: string;
  className?: string;
  multiple?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
}

const Select: FC<SelectProps> = ({ label, options, id, className, placeholder, style, ...props }) => {
  const classNames = cn('select-container', className);

  const labelClassName = cn('label', {
    label: props.value,
  });

  return (
    <div className={classNames} style={style}>
      {label && label != '' && (
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
      )}

      <select id={id} {...props}>
        {placeholder && (
          <option disabled value="" hidden>
            {placeholder}
          </option>
        )}
        {options.map((option: Option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;