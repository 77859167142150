import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import LoginPage  from './pages/Login';
import TestPage  from './pages/Test';
import HomePage from "./pages/Home";
import ComunicacionesPage from "./pages/Comunicaciones";
import GestionEnviosPage from "./pages/GestionEnvios/index";
import DescargaFicherosPage from "./pages/DescargaFicheros/index";
import GestionUsuariosPage from "./pages/GestionUsuarios/index";
import CrearEditarUsuariosPage from "./pages/GestionUsuarios/Usuarios/CrearEditar/index";
import CrearEditarRolesPage from "./pages/GestionUsuarios/Roles/CrearEditar/index";

const fallback = null;

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route key='login' path="/login" element={<LoginPage />} />
        <Route key='home' path="/home" element={<HomePage />} />
        <Route key='parques' path="/parques" element={<ComunicacionesPage />} />
        <Route key='comunicaciones' path="/comunicaciones" element={<ComunicacionesPage />} />
        <Route key='gestion-envios' path="/gestion-envios" element={<GestionEnviosPage />} />
        <Route key='descarga-ficheros' path="/descarga-ficheros" element={<DescargaFicherosPage />} />
        <Route key='gestion-usuarios' path="/gestion-usuarios/:tab" element={<GestionUsuariosPage />} />
        <Route key='gestion-usuarios-crear' path="/gestion-usuarios/usuarios/crear" element={<CrearEditarUsuariosPage />} />
        <Route key='gestion-usuarios-editar' path="/gestion-usuarios/usuarios/editar/:id" element={<CrearEditarUsuariosPage />} />
        <Route key='gestion-usuarios-roles-crear' path="/gestion-usuarios/roles/crear" element={<CrearEditarRolesPage />} />
        <Route key='gestion-usuarios-roles-editar' path="/gestion-usuarios/roles/editar/:id" element={<CrearEditarRolesPage />} />

        <Route key='test' path="/test" element={<TestPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;