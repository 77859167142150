import React from 'react';
import '../../assets/scss/components/_textarea.scss';

interface TextareaProps {
  name?: string;
  style?: React.CSSProperties;
  label?: string;
  placeholder?: string;
  value?: string;
  onChange: (newValue: string) => void;
  readOnly?: boolean;
}

const Textarea: React.FC<TextareaProps> = ({ name, style, label, placeholder, value, onChange, readOnly }) => {
  const [formattedJson, setFormattedJson] = React.useState('');

  React.useEffect(() => {
    // Actualizar el estado local cuando cambia el valor externo (value)
    try {
      if (value !== 'undefined') {
        const jsonValue = JSON.parse(value || '');
        setFormattedJson(JSON.stringify(jsonValue, null, 2));
      }
    } catch (error) {
      // Si hay un error al formatear el JSON, muestra el texto original
      setFormattedJson(value || '');
    }
  }, [value]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.altKey) {
      // Evitar el salto de línea si la tecla Alt está presionada
      e.preventDefault();

      // Obtener la posición del cursor
      const cursorPosition = e.currentTarget.selectionStart;

      // Obtener el texto antes y después de la posición del cursor
      const textBeforeCursor = formattedJson.slice(0, cursorPosition);
      const textAfterCursor = formattedJson.slice(cursorPosition);

      // Actualizar el texto con el salto de línea en la posición del cursor
      setFormattedJson(`${textBeforeCursor}\n${textAfterCursor}`);

      // Mover el cursor a la posición correcta después del salto de línea
      const newCursorPosition = cursorPosition + 1;
      e.currentTarget.setSelectionRange(newCursorPosition, newCursorPosition);
    }
  };

  return (
    <div className="textarea-container">
      <textarea
        name={name}
        style={style}
        placeholder={placeholder}
        value={formattedJson}
        onChange={(e) => setFormattedJson(e.target.value)}
        onKeyDown={handleKeyDown}
        readOnly={readOnly}
      />
    </div>
  );
};

export default Textarea;