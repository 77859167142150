import React, { useState, ChangeEvent, useEffect } from 'react';
import '../../assets/scss/components/_radio-button.scss';

interface RadioButtonProps {
  label: string;
  options: { value: string; text: string }[];
  onChange?: (selectedValue: string) => void;
  value: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({ label, options, onChange, value }) => {
  const [selectedOption, setSelectedOption] = useState<string>(value);

  useEffect(() => {
    // Seleccionar la primera opción por defecto
    if (options.length > 0 && !value) {
      setSelectedOption(options[0].value);
    }
  }, [options, value]);

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSelectedOption(newValue);

    // Llamar a la función onChange si está definida
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className="radio-button-container">
      <p>{label}</p>
      {options.map((option) => (
        <label className="label" key={option.value}>
          <input
            type="radio"
            name={label}
            value={option.value}
            checked={selectedOption === option.value}
            onChange={handleOptionChange}
          />
          {option.text}
        </label>
      ))}
    </div>
  );
};

export default RadioButton;