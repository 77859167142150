import React, { useState } from 'react';
import '../../assets/scss/pages/_descarga-ficheros.scss';
import Input from '../../components/Input';
import Navbar from "../../components/Navbar";
import Button from "../../components/Button";
import DescargaFicherosIcon from '../../assets/icons/circle-download-blanco.svg';
import SuccessIcon from '../../assets/icons/circle-check.svg';
import AlertIcon from '../../assets/icons/alert-octagon.svg';
import {postData} from "../../API/index";
import axios, { AxiosResponse, AxiosError } from 'axios';
import { appConfig } from '../../config/app';
import { useAuth } from '../../context/AuthContext';
import { useNavigate, NavigateFunction, Navigate } from 'react-router-dom';

function DescargaFicherosPage() {
  type ResponseStatus = 'success' | 'error' | null;

  const [fecha, setFecha] = useState('');
  const [responseStatus, setResponseStatus] = useState<ResponseStatus>(null);
  const { token, refreshAccessToken } = useAuth();
  const [errorMensaje, setErrorMensaje] = useState("El fichero no se ha podido descargar. Inténtelo de nuevo");
  const navigate = useNavigate() as NavigateFunction;

  const handleDescargarClick = async () => {
    const headers = {
      'Content-Type': 'application/json',
    };

    // Hacer el post de la descarga
    const data = {
      'fecha': fecha
    };

    try {
      const response: AxiosResponse = await postData(`/api/ficherosXML`, JSON.stringify(data), token, refreshAccessToken, navigate, headers);

      // Verificar el estado solo si la solicitud fue exitosa (sin errores 404 u otros)
      if (response && response.status === 200) {
        window.open(
          `${appConfig.apiConfig.baseURL}/${response?.data?.file}`,
          '_blank',
        );

        setResponseStatus('success');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 404) {
          setErrorMensaje(error.response.data.message);
        }
      } else {
        // Si no es un AxiosError, manejarlo de acuerdo a tus necesidades
        setErrorMensaje('Error desconocido:' + error);
      }
      setResponseStatus('error');
    }
  };

  const handleNewDownload = () => {
    setResponseStatus(null);
  };

  return (
    <div className="descarga-ficheros-page">
      <div className="cabecera">
        <Navbar />
        <div className="title">
          <img src={DescargaFicherosIcon} alt="Descarga de ficheros" />
          <h2>Descarga de ficheros</h2>
        </div>
      </div>

      <div className="main-content">

        <div className="container">

          {responseStatus === null && (
            <div>
              <Input
                label="Seleccionar fecha"
                value={fecha}
                placeholder="Seleccionar fecha"
                type="date"
                onChange={(value) => setFecha(value)}
              />

              <Button color="primary" onClick={handleDescargarClick}>Descargar</Button>
            </div>
          )}

          {responseStatus === 'success' && (
            <div className="success-container">
              <img src={SuccessIcon} alt="Success Icon" />
              <p>Fichero descargado</p>
              <Button color="white"onClick={handleNewDownload}>Realizar nueva descarga</Button>
            </div>
          )}

          {responseStatus === 'error' && (
            <div className="error-container">
              <div>
                <img src={AlertIcon} alt="Alert Icon" />
                <p className="error-msg">{errorMensaje}</p>
              </div>
              <Button color="white"onClick={handleNewDownload}>Realizar nueva descarga</Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DescargaFicherosPage;