import React, { useRef, useState, ChangeEvent, DragEvent } from 'react';
import '../../assets/scss/components/_file-upload-btn.scss';
import DeleteIcon from '../../assets/icons/delete-red.svg';
import UploadIcon from '../../assets/icons/cloud-upload.svg';

interface FileUploadButtonProps {
  type: 'foto' | 'archivo';
  onSubmit: (file: File) => void;
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({ type, onSubmit }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      if (onSubmit) {
        onSubmit(file);
      }
    }
  };

  const handleClearFile = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      setSelectedFile(file);
      if (onSubmit) {
        onSubmit(file);
      }
    }
  };

  return (
    <div
      className="file-upload-btn-container"
      onClick={handleDivClick}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {!selectedFile && (
        <>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <img src={UploadIcon} alt="Upload Icon" />
        {type == 'archivo' && (
          <p>
            <b>Click para subir un archivo</b>
            <br />
            o arrástralo aquí
          </p>
        )}

        {type == 'foto' && (
          <div className="photo-text">
            <p>
              <b>Click para subir una foto</b> o arrástrala aquí
            </p>
            <p>SVG, PNG, JPG (max. 800x400px)</p>
          </div>
        )}
        </>
      )}

      {selectedFile && (
        <div className="selected-file-container">
          <span>{selectedFile.name}</span>
          <img src={DeleteIcon} onClick={handleClearFile} alt="Delete Icon" />
        </div>
      )}
    </div>
  );
};

export default FileUploadButton;