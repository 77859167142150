import React, { useState, useEffect, ChangeEvent } from 'react';
import Select from "../Select/index";
import DeleteIcon from '../../assets/icons/delete-red.svg';
import AddIcon from '../../assets/icons/plus-circle.svg';
import Input from "../Input/index";
import '../../assets/scss/components/_criteriabox.scss';
import Button from "../Button/index";
import { v4 as uuidv4 } from 'uuid';

interface CriteriaRowProps {
  id: string;
  options: { value: string; text: string }[];
  onDelete: (id: string) => void;
  onCriteriaChange: (index: string, updatedCriteria: { id: string; campo: string; operador: string; valor: string }) => void;
}

const operadorOptions = [
  { value: '=', text: 'Igual a' },
  { value: '>', text: 'Mayor que' },
  { value: '<', text: 'Menor que' },
  { value: '>=', text: 'Mayor o igual que' },
  { value: '<=', text: 'Menor o igual que' },
  { value: '!=', text: 'Distinto a' },
  { value: 'EW', text: 'Que contenga1' },
  { value: 'SW', text: 'Que contenga2' },
];

const CriteriaRow: React.FC<CriteriaRowProps> = ({ id, options, onDelete, onCriteriaChange }) => {

  const [selectedValues, setSelectedValues] = useState<{ campo: string; operador: string; valor: string }>(
    { campo: options[0]?.value || '', operador: operadorOptions[0]?.value || '', valor: '' }
  );

  const [inputValue, setInputValue] = useState<string>('');

  const handleDropdownChange = (field: string, value: string) => {
    setSelectedValues((prevValues) => ({ ...prevValues, [field]: value }));
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  const handleDelete = () => {
    onDelete(id);
  };

  useEffect(() => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      campo: options[0].value
    }));
  }, [options]);

  useEffect(() => {
    const criteriaObject = {
      id: id,
      campo: selectedValues.campo,
      operador: selectedValues.operador,
      valor: inputValue,
    };
    onCriteriaChange(id, criteriaObject);
  }, [selectedValues, inputValue, id]);

  return (
    <div className="criteria-row">
      <div>
        <Select
          options={options}
          value={selectedValues.campo}
          onChange={(e) => handleDropdownChange('campo', e.target.value)}
        />

        <Select
          options={operadorOptions}
          value={selectedValues.operador}
          placeholder="Operador"
          onChange={(e) => handleDropdownChange('operador', e.target.value)}
        />

        <Input value={inputValue} placeholder="Criterio" onChange={(value) => handleInputChange(value)} />
      </div>

      <img src={DeleteIcon} onClick={(e) => onDelete(id)} alt="Delete Icon" />
    </div>
  );
};

interface CriteriaboxProps {
  initialOptions: { value: string; text: string }[];
  onCriteriaChange: (values: {
    id?: string;
    campo: string;
    operador: string;
    valor: string;
  }[]) => void;
}

const Criteriabox: React.FC<CriteriaboxProps> = ({ initialOptions, onCriteriaChange }) => {
  const [criterios, setCriterios] = useState<{ id: string, campo: string, operador: string, valor: string }[]>([
    { id: uuidv4(), campo: initialOptions[0]?.value || '', operador: operadorOptions[0]?.value || '', valor: '' }
  ]);

  const addRow = () => {
    const newCriterio = { id: uuidv4(), campo: initialOptions[0]?.value || '', operador: operadorOptions[0]?.value || '', valor: '' };
    setCriterios((prevCriterios) => [...prevCriterios, newCriterio]);
  };

  const deleteRow = (id: string) => {
    if (criterios.length > 1) {
      const newCriterios = criterios.filter(criterio => criterio.id !== id);
      setCriterios(newCriterios);
    }
  };

  const handleCriteriaChange = (id: string, newCriteria: { campo: string; operador: string; valor: string }) => {
    const updatedCriterios = criterios.map(criterio =>
      criterio.id === id ? { id, ...newCriteria } : criterio
    );
    setCriterios(updatedCriterios);
    onCriteriaChange(updatedCriterios);
  };

  return (
    <div className="criteriabox-container">
      <p className="label">Criterios</p>
      <div className="rows">
        {criterios.map((criterio, index) => (
          <CriteriaRow
            key={criterio.id}
            id={criterio.id}
            options={initialOptions}
            onDelete={() => deleteRow(criterio.id)}
            onCriteriaChange={(id, updatedCriteria) => handleCriteriaChange(id, updatedCriteria)}
          />
        ))}
      </div>
      <div className="buttons-container">
        <Button color="transparent-border" onClick={addRow}>
          <img src={AddIcon} alt="Add Icon"  /> Añadir Criterio
        </Button>
      </div>
    </div>
  );
};

export default Criteriabox;