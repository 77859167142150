import React, {useState, useEffect, useContext, SetStateAction} from "react";
import Select from "../../../components/Select/index";
import RadioButton from "../../../components/RadioButton/index";
import Button from "../../../components/Button/index";
import Criteriabox from "../../../components/Criteriabox/index";
import SuccessIcon from "../../../assets/icons/circle-check.svg";
import AlertIcon from "../../../assets/icons/alert-octagon.svg";
import HelpIcon from "../../../assets/icons/help.svg";
import Modal from "./Modal/index";
import { postData } from "../../../API/index";
import { AxiosResponse } from "axios";
import { appConfig } from "../../../config/app";
import { useAuth } from "../../../context/AuthContext";
import {useNavigate, NavigateFunction, Link} from "react-router-dom";
import { ComunicacionesContext } from "..";

const ConsultasTab = () => {
  type ResponseStatus = "success" | "error" | null;

  const { selectedOption } = useContext(ComunicacionesContext);
  const [selectedMode, setSelectedMode] = useState("AND");
  const [responseStatus, setResponseStatus] = useState<ResponseStatus>(null);
  const [esUltimaPagina, setEsUltimaPagina] = useState(true);
  const [indiceResultadoConsulta, setindiceResultadoConsulta] =
    useState<number>();
  const [resultadosTotales, setResultadosTotales] = useState<number>();
  const [numeroResultados, setNumeroResultados] = useState<number>();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedModalOption, setSelectedModalOption] = useState("");
  const [criteriaValues, setCriteriaValues] = useState<
    { campo: string; operador: string; valor: string }[]
  >([]);
  const urlConsulta = "/api/consulta/parques/";
  const { token, user, refreshAccessToken } = useAuth();
  const navigate = useNavigate() as NavigateFunction;
  const [errorText, setErrorText] = useState("");

  const [modalOptions, setModalOptions] = useState([
    { value: "1", text: "Grupo 1: Elementos 1 - 1.000" },
    { value: "2", text: "Grupo 2: Elementos 1.000 - 2.000" },
  ]);

  const criterioOptionsActuacion = [
    { value: "idActuacion", text: "ID actuacion" },
    { value: "barrio", text: "Barrio" },
    { value: "codigoPostal", text: "Codigo postal" },
    { value: "concesionaria", text: "Concesionaria" },
    { value: "contrato", text: "Contrato" },
    { value: "distrito", text: "Distrito" },
    { value: "estado", text: "Estado" },
    { value: "idActuacionExterno", text: "ID actuación externo" },
    { value: "idInventario", text: "ID inventario" },
    { value: "localizacion", text: "Localizacion" },
    { value: "lote", text: "Lote" },
    { value: "ndp", text: "NDP" },
    { value: "nombreVia", text: "Nombre vía" },
    { value: "numeroActo", text: "Número acto" },
    { value: "numeroAvisoOrigen", text: "Número aviso origen" },
    { value: "numeroVia", text: "Número vía" },
    { value: "ordenDirecta", text: "Orden directa" },
    { value: "servicio", text: "Servicio" },
    { value: "tipoElemento", text: "Tipo elemento" },
    { value: "tipoVia", text: "Tipo vía" },
    { value: "zona", text: "Zona" },
  ];

  const criterioOptionsInventario = [
    { value:"idInventario", text: "Id inventario" },
    { value:"usuarioEditor", text: "Usuario editor" },
    { value:"descripcion", text: "Descripción" },
    { value:"descripcionLarga", text: "Descripción larga" },
    { value:"fechaInstalacion", text: "Fecha instalación" },
    { value:"barrio", text: "Barrio" },
    { value:"distrito", text: "Distrito" },
    { value:"barrioDescripcion", text: "Barrio descripción" },
    { value:"distritoDescrpcion", text: "Distrito descrpción" },
    { value:"idInventarioExterno", text: "Id inventario externo" },
    { value:"codigoPostal", text: "Código Postal" },
    { value:"contrato", text: "Contrato" },
    { value:"contratoDescripcion", text: "Contrato descripción" },
    { value:"descripcionUbicacion", text: "Descripción ubicación" },
    { value:"jerarquiaClasificacion", text: "Jerarquía clasificación" },
    { value:"jerarquiaClasificacionDescripcion", text: "Jerarquía clasificacion descripción" },
    { value:"claseFuncionGIS", text: "claseFuncionGIS" },
    { value:"lote", text: "Lote" },
    { value:"loteDescripcion", text: "Lote descripción" },
    { value:"ndp", text: "NDP" },
    { value:"tipoVia", text: "Tipo vía" },
    { value:"nombreVia", text: "Nombre vía" },
    { value:"numeroVia", text: "Numero vía" },
    { value:"observaciones", text: "Observaciones" },
    { value:"observacionesLargas", text: "Observaciones largas" },
    { value:"servicio", text: "Servicio" },
    { value:"servicioDescripcion", text: "Servicio descripción" },
    { value:"padre", text: "Padre" },
    { value:"posicionamientoMapa", text: "Posicionamiento mapa" },
    { value:"idInventarioInterno", text: "Id inventario interno" },
    { value:"estado", text: "Estado" },
    { value:"fechaEstado", text: "Fecha estado" },
    { value:"grupoParque", text: "Grupo parque" },
    { value:"grupoParqueDescripcion", text: "Grupo parque descripción" },
    { value:"parque", text: "Parque" },
    { value:"parqueDescripcion", text: "Parque descripción" },
    { value:"zona", text: "Zona" },
    { value:"zonaDescripcion", text: "Zona descripción" },
    { value:"instalacion", text: "Instalacion" },
    { value:"instalacionCultivo", text: "Instalación cultivo" },
    { value:"sistema", text: "Sistema" },
    { value:"fechaPlantacion", text: "Fecha plantación" },
    { value:"fechaDesinstalacion", text: "Fecha desinstalación" },
    { value:"fechaAltaMantenimiento", text: "Fecha alta mantenimiento" },
    { value:"fechaBajaMantenimiento", text: "Fecha baja mantenimiento" },
    { value:"fechaPrimeraCertificacion", text: "Fecha primera certificación" },
    { value:"fechaUltimaCertificacion", text: "Fecha última certificación" },
    { value:"fechaCaducidad", text: "Fecha caducidad" },
    { value:"fechaDeUltimaRevision", text: "Fecha de última revisión" },
    { value:"cups", text: "Cups" },
    { value:"empresaSuministradora", text: "Empresa suministradora" },
    { value:"rotacion", text: "Rotación" },
    { value:"comentarioUso", text: "Comentario uso" },
    { value:"comentarioUsoLargo", text: "Comentario uso largo" },
    { value:"fechaTala", text: "Fecha tala" },

  ];

  const [criterioOptions, setCriterioOptions] = useState(
    criterioOptionsActuacion
  );

  const resultadosEsperados = 100;

  const consultarEnOptions = [
    {
      text: "Inventario",
      value: "inventario",
      permission: "export-inventario",
    },
    //{ text: 'Actuaciones', value: 'actuaciones', permission: 'export-actuaciones' },
  ];

  const modoOptions = [
    { value: "AND", text: "AND" },
    { value: "OR", text: "OR" },
  ];


  const [disableButton, setDisableButton] = useState(false);
  const changeDisableButton = (disableState: SetStateAction<boolean>) => setDisableButton(disableState);

  const [buttonText, setButtonText] = useState("Consultar");
  const changeText = (text: SetStateAction<string>) => setButtonText(text);

  const handleConsultarClick = async () => {
    lanzarConsulta();
  };

  const lanzarConsulta = async (index?: number) => {
    changeText("Consultando...");
    changeDisableButton(true);
    const headers = {
      "Content-Type": "application/json",
    };

    const data = {
      consultas: criteriaValues.map((item) => ({
        campo: item.campo,
        operador: item.operador,
        valor: item.valor,
      })),
      indiceResultadoConsulta: index ?? indiceResultadoConsulta,
      resultadosEsperados: resultadosEsperados,
      modoBusqueda: selectedMode,
    };

    try {
      const response: AxiosResponse = await postData(
        `${urlConsulta}${selectedOption}`,
        { data: JSON.stringify(data) },
        token,
        refreshAccessToken,
        navigate,
        headers
      );

      setErrorText("");

      if (response && response.status >= 200 && response.status < 300) {
        const numeroResultados = parseInt(response?.data?.numeroResultados, 10);
        if (isNaN(numeroResultados)) {
          setErrorText(response?.data?.message);
          setResponseStatus("error");
        } else {
          setindiceResultadoConsulta(
            parseInt(response?.data?.indiceResultadoInicial, 10)
          );
          setResultadosTotales(parseInt(response?.data?.resultadosTotales, 10));
          setNumeroResultados(numeroResultados);

          const ultima =
            response?.data?.indiceResultadoInicial *
              response?.data?.numeroResultados >=
            response?.data?.resultadosTotales -
              response?.data?.numeroResultados;

          setEsUltimaPagina(ultima);

          /*window.open(
            `${appConfig.apiConfig.baseURL}/${response?.data?.file}`,
            "_blank"
          );*/

          setResponseStatus("success");
          setModalOpen(false);
        }
      } else {
        console.error(
          "La solicitud no fue exitosa:",
          response.status,
          response.statusText
        );
        setResponseStatus("error");
      }
    } catch (error) {
      setResponseStatus("error");
    }
    changeText("Consultar");
    changeDisableButton(false);
  };

  const handleNewUpload = () => {
    setResponseStatus(null);
  };

  const handleCriteriaChange = (
    values: {
      campo: string;
      operador: string;
      valor: string;
    }[]
  ) => {
    setCriteriaValues(values);
  };

  const abrirModal = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    setModalOptions(calcularPaginas());
  }, [resultadosTotales, resultadosEsperados]);

  useEffect(() => {
    if (selectedOption === "actuaciones") {
      setCriterioOptions(criterioOptionsActuacion);
    } else if (selectedOption === "inventario") {
      setCriterioOptions(criterioOptionsInventario);
    }
  }, [selectedOption]);

  const calcularPaginas = () => {
    const opciones = [];

    if (
      resultadosTotales &&
      resultadosTotales > 0 &&
      resultadosEsperados &&
      resultadosEsperados > 0
    ) {
      const paginas = Math.ceil(resultadosTotales / resultadosEsperados);

      for (let pagina = 1; pagina <= paginas; pagina++) {
        const inicio = resultadosEsperados * (pagina - 1) + 1;
        const fin = Math.min(resultadosEsperados * pagina, resultadosTotales);

        const opcion = {
          value: pagina.toString(),
          text: `Grupo ${pagina}: Elementos ${inicio} - ${fin}`,
        };

        opciones.push(opcion);
      }
    }

    return opciones;
  };

  const consultarEnOptionsFilteredOptions = consultarEnOptions.filter(
    (option: any) =>
      user.permisos.some(
        (permission: any) => permission.nombre === option.permission
      )
  );

  return (
    <div className="consultas-container">
      {responseStatus === null && (
        <div>
          <span className="tooltip">
            <img
              src={HelpIcon}
              alt="Ayuda"
              style={{ width: "15px", height: "15px", cursor: "pointer" }}
            />
            <span className="tooltiptext">
              Modo (operador lógico) con el que se realizará la búsqueda.
            </span>
          </span>

          <RadioButton
            label="Modo"
            options={modoOptions}
            value={selectedMode}
            onChange={(value) => setSelectedMode(value)}
          />

          <Criteriabox
            initialOptions={criterioOptions}
            onCriteriaChange={(values) => handleCriteriaChange(values)}
          />

          <Button color="primary" isDisabled={disableButton} onClick={handleConsultarClick}>
            {buttonText}
          </Button>
        </div>
      )}

      {responseStatus === "success" && (
        <div className="success-container">
          <img src={SuccessIcon} alt="Success Icon" />

          <div>
            <p className="message">
              Consulta planificada correctamente
            </p>
            <p className="submessage">
              {resultadosTotales} elementos a consultar
            </p>
          </div>

          <div className="buttons-container">
            <Link to="/gestion-envios">
              <Button color="primary">Ir a gestión de envíos</Button>
            </Link>
          </div>

          <Button color="transparent" onClick={handleNewUpload}>
            Realizar nueva consulta
          </Button>
        </div>
      )}
      {responseStatus === "error" && (
        <div className="error-container">
          <div>
            <img src={AlertIcon} alt="Alert Icon" />
            <p className="error-msg">
              {errorText !== ""
                ? errorText
                : "El documento no se ha podido descargar. Inténtelo de nuevo"}
            </p>
          </div>
          <Button color="transparent" onClick={handleNewUpload}>
            Realizar nueva consulta
          </Button>
        </div>
      )}
    </div>
  );
};

export default ConsultasTab;
