import React from 'react';
import { InputType } from '../../shared/types';
import '../../assets/scss/components/_input.scss';

interface InputProps {
  label?: string;
  value?: string;
  name?: string;
  placeholder: string;
  type?: InputType;
  onChange: (newValue: string) => void;
  iconSrc?: string;
  style?: React.CSSProperties;
  readOnly?: boolean;
}

const Input: React.FC<InputProps> = ({ label, value, name, placeholder, type = "text", onChange, iconSrc, style, readOnly }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="input-container" style={style}>
      {label && <label className="label">{label}</label>}
      <div className="input-wrapper">
        {iconSrc && <img src={iconSrc} alt="Icono" className="input-icon" />}
        <input
          className={iconSrc ? "withIcon" : ""}
          type={type}
          value={value !== 'null' && value !== 'undefined' ? value : ''}
          name={name}
          placeholder={placeholder}
          onChange={handleChange}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

export default Input;