import { createContext, useState, useContext, useEffect } from 'react';
import { postData, getData, deleteData } from "../API/index";

const AuthContext = createContext();

// Función para guardar el usuario
const saveUser = async (setUser, userData) => {
    try {
        await setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        console.log('Usuario guardado correctamente:', userData);
    } catch (error) {
        console.error('Error al guardar el usuario:', error);
        throw error;
    }
};

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState('');
    const [user, setUser] = useState('');

    const refreshAccessToken = async () => {
        try {
            const storedRememberToken = localStorage.getItem('rememberToken');

            if (!storedRememberToken) {
                return null;
            }

            // Usando postData para refrescar el token
            const response = await postData('/refresh-token', { remember_token: storedRememberToken }, token);
            const { newToken } = response.data;

            // Actualiza el Access Token en el estado y localStorage
            setToken(newToken);
            localStorage.setItem('accessToken', newToken);
            return newToken;
        } catch (error) {
            console.error('Error al refrescar el token:', error);
            return null;
        }
    };

    const login = async (loginData, navigate) => {
        try {
            // Token
            const response = await postData('/login', loginData, null, refreshAccessToken);
            const token = response.data.token;
            const remember_token = response.data.remember_token;

            // Almacena el Access Token y el Refresh Token
            await setToken(token);
            localStorage.setItem('accessToken', token);
            localStorage.setItem('rememberToken', remember_token);

            // Usuario
            const userResponse = await getData('/user', navigate, token, refreshAccessToken);
            const userData = userResponse.data;
            await saveUser(setUser, userData);
        } catch (error) {
            console.error('Error en el inicio de sesión:', error);
            throw error;
        }
    };

    // Función para realizar el logout
    const logout = async (navigate) => {
        try {
            await deleteData('/logout', navigate, token);
        } catch (error) {
            console.error('Error en logout:', error);
        }

        const rememberUser = localStorage.getItem('remember');

        if (!rememberUser) { // Si no se desea recordar, elimina el token y el usuario
            localStorage.removeItem('accessToken');
            localStorage.removeItem('user');
        }

        setToken('');
        setUser('');
        navigate('/login');
    };


    useEffect(() => {
        const storedToken = localStorage.getItem('accessToken');
        const storedRefreshToken = localStorage.getItem('refreshToken');
        const rememberUser = localStorage.getItem('remember');

        if (storedToken) {
            setToken(storedToken);
        } else if (rememberUser && storedRefreshToken) {
            refreshAccessToken(); // Intenta refrescar el token
        }

        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }

    }, []);

    return (
        <AuthContext.Provider value={{ token, setToken, user, setUser, login, logout, saveUser, refreshAccessToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export { saveUser };