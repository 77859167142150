import React from 'react';


interface IconProps {
  name: string;
  color: string;
}

const Icon: React.FC<IconProps> = ({  }) => {

  return (
    <div></div>
  );
};

export default Icon;